<template>
    <div class="up_content_inner privacyform_page_wrapper">
        <div class="up_main_container">
            <div class="up_page_header">
                <h2 class="up_page_title">Privacy Form Leads</h2>
            </div>

            <div class="up_inner_container">
                <!--- Show Website Table Start --->
                <DataTable class="p-datatable p-mb-2" :paginator="true" stripedRows :value="allLeadData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="" :rowHover="true" :loading="allLeadData.loading" responsiveLayout="scroll" removableSort :filters="filters" :dataKey="uuid">
                    <template #loading>
                        Loading privacy form leads data. Please wait.
                    </template>
                    <!-- <template #header>
                        <div class="p-d-flex p-jc-end">
                            <div class="p-d-flex p-align-center">
                                <span class="p-input-icon-left sup_search_box">
                                    <i class="pi pi-search" />
                                    <InputText v-model="allBrandingData.search" placeholder="Search Branding" @keyup.enter="searchAllBrandingData()"/>
                                </span>
                                <Button v-if="allBrandingData.search !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllBrandingData('resetFilters')"/>
                            </div>
                        </div>
                    </template> -->
                    <template #empty>
                        No leads found.
                    </template>
                    <Column field="siteName" header="Website" headerStyle="width: 20rem" :sortable="true">
                        <template #body="slotProps">
                            <div class="p-d-flex">
                                <Button type="button" icon="pi pi-plus" class="p-button-sm p-button-rounded p-button-outlined p-button-gray p-mr-2 p-f" v-tooltip="'View More'" @click="viewLeadById(slotProps.data.uuid)" />
                                <div class="up_website_info">
                                    <span class="sup_site_info p-d-block">{{slotProps.data.Website.siteName}}</span>
                                    <a :href="'https://' + [slotProps.data.Website.siteUrl]" class="sup_website_url" target="_blank">{{slotProps.data.Website.siteUrl}}</a>
                                </div>
                            </div>
                        </template>
                    </Column>

                    <Column field="name" header="Name" style="text-align: center">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.firstName && slotProps.data.lastName">{{slotProps.data.firstName}} {{slotProps.data.lastName}}</span>
                            <span v-else >--</span>
                        </template>
                    </Column>

                    <Column field="email" header="Email" style="text-align: center">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.email">{{slotProps.data.email}}</span>
                            <span v-else >--</span>
                        </template>
                    </Column>

                    <Column field="ipAddress" header="Ip Address" style="text-align: center">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.ipAddress">{{slotProps.data.ipAddress}}</span>
                            <span v-else >--</span>
                        </template>
                    </Column>

                    <Column field="address" header="Address" style="text-align: center">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.state && slotProps.data.city">{{slotProps.data.city}}<br>{{slotProps.data.state}}</span>
                            <span v-else >--</span>
                        </template>
                    </Column>

                    <Column field="createdAt" header="Requested on" style="text-align: center">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.createdAt">{{usFormatDate(slotProps.data.createdAt)}}<br>
                            <span style="font-size: 12px; color: red;">{{daysRemaining(slotProps.data.createdAt)}}</span></span>
                            <span v-else >--</span>
                        </template>
                    </Column>

                    <Column field="action" header="Actions" style="text-align: center">
                        <template #body="slotProps">
                            <div>
                                <!-- <Button type="button" :icon="slotProps.data.isRequested === 'notRequested' ? 'pi pi-send' :
                                slotProps.data.isRequested === 'requested' ? 'pi pi-exclamation-triangle' : 'pi pi-check'" 
                                :class="['p-button-sm p-button-rounded', slotProps.data.isRequested === 'notRequested' ? 'p-button-danger' :
                                slotProps.data.isRequested === 'requested' ? 'p-button-warning' : 'p-button-success']" 
                                v-tooltip.left = "slotProps.data.isRequested === 'notRequested' ? 'Request to approve' :
                                slotProps.data.isRequested === 'requested' ? 'Pending Approval' : 'Approved'" @click="slotProps.data.isRequested === 'notRequested' ? sendApproveRequest(slotProps.data.uuid) : ''"/> -->
                                <Button v-if="slotProps.data.isRequested === 'notRequested'" type="button" class="p-button-warning request" @click="sendApproveRequest(slotProps.data.uuid)"><i class="pi pi pi-send"></i>Request to admin</Button>
                                <span v-else :class="['up_item_status ', slotProps.data.isRequested === 'requested' ? 'up_item_status_warning' : slotProps.data.isRequested === 'approved' ?'up_item_status_success' : '']">
                                <i :class="slotProps.data.isRequested === 'notRequested' ? 'pi pi-send' :
                                slotProps.data.isRequested === 'requested' ? 'pi pi-exclamation-triangle' : 'pi pi-check'"></i>
                                {{slotProps.data.isRequested === 'requested' ? 'Approval Pending' : slotProps.data.isRequested === 'approved' ? 'Approved' : ''}}
                                </span>
                            </div>
                        </template>
                    </Column>
                </DataTable>
                <!--- Show Website Table End --->
            </div>
        </div>
        <Dialog v-model:visible="viewPrivacyLeadsModel.modalState" :modal="true" :style="{width: '600px'}">
            <template #header>
                <span class="p-dialog-title">
                    Lead Details - {{viewPrivacyLeadsModel.modaldata.email}}
                </span>
            </template>
            <div class="sup_dialog_content sup_Branding_info">
                <div class="sup_privacy_form_leads_section">
                    <div class="sup_section_title">Website info:</div>
                    <ul>
                        <li><strong>Website Name:</strong> {{viewPrivacyLeadsModel.modaldata.Website.siteName.charAt(0).toUpperCase() + viewPrivacyLeadsModel.modaldata.Website.siteName.slice(1)}}</li>
                        <li><strong>Website Url:</strong> <a target="_blank" :href="'http://' + viewPrivacyLeadsModel.modaldata.Website.siteUrl">{{viewPrivacyLeadsModel.modaldata.Website.siteUrl}}</a></li>
                    </ul>
                </div>
                <div v-if="viewPrivacyLeadsModel.modaldata !== null" class="sup_privacy_form_leads_section">
                    <div class="sup_section_title">Form Lead Details:</div>
                    <ul>
                        <li><strong>Lead Name:</strong> {{viewPrivacyLeadsModel.modaldata.firstName}} {{viewPrivacyLeadsModel.modaldata.lastName}}</li>
                        <li><strong>Lead Ip Address:</strong> {{viewPrivacyLeadsModel.modaldata.ipAddress}}</li>
                        <li><strong>Content Sent:</strong> {{viewPrivacyLeadsModel.modaldata.isContentSent === true ? 'Yes' : 'No'}} </li>
                        <li><strong>Data Used For Marketing:</strong> {{viewPrivacyLeadsModel.modaldata.isDataUsedForMarketing === true ? 'Yes' : 'No'}} </li>
                        <li><strong>Deleted All Stored Information:</strong> {{viewPrivacyLeadsModel.modaldata.isDeletedAllStoredInformation === true ? 'Yes' : 'No'}} </li>
                        <li><strong>Deleted Card Information:</strong> {{viewPrivacyLeadsModel.modaldata.isDeletedCardInformation === true ? 'Yes' : 'No'}} </li>
                        <li><strong>Received Newsletter:</strong> {{viewPrivacyLeadsModel.modaldata.isReceivedNewsletter === true ? 'Yes' : 'No'}} </li>
                        <li><strong>Sold Information:</strong> {{viewPrivacyLeadsModel.modaldata.isSoldInformation === true ? 'Yes' : 'No'}} </li>
                        <li><strong>Lead Address:</strong>
                            {{viewPrivacyLeadsModel.modaldata.streetAddress}},{{viewPrivacyLeadsModel.modaldata.city}}<br>
                            {{viewPrivacyLeadsModel.modaldata.state}},{{viewPrivacyLeadsModel.modaldata.zip}}
                        </li>
                        <li><strong>Actions:</strong> 
                            <span :class="['up_item_status ', viewPrivacyLeadsModel.modaldata.isRequested === 'requested' ? 'up_item_status_warning' : viewPrivacyLeadsModel.modaldata.isRequested === 'approved' ?'up_item_status_success' : 'up_item_status_danger']" >
                                <i :class="viewPrivacyLeadsModel.modaldata.isRequested === 'notRequested' ? 'pi pi-send' :
                                viewPrivacyLeadsModel.modaldata.isRequested === 'requested' ? 'pi pi-exclamation-triangle' : 'pi pi-check'"></i>
                                {{viewPrivacyLeadsModel.modaldata.isRequested === 'requested' ? 'Pending for approval' : viewPrivacyLeadsModel.modaldata.isRequested === 'approved' ? 'Approved' : 'Request to admin'}}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </Dialog>
    </div>
    <Toast position="top-right" />
</template>

<script>
import { allPrivacyLeads, approveRequests, UpPrivacyLeadById } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
// import { imageUrl } from '../../../../config/constant';
// import { validateEmail, validateMobile, validatePassword, matchPassword } from "../../../../helper/validationProcess/ValidationProcess";
// import { currentMenuUuid } from '../../../../helper/pageActions/pageActions';
import { formatDate, usFormatDate } from '../../../../helper/utility/utility';
import { useToast } from "primevue/usetoast";
import axios from "axios";
import { useStore } from 'vuex';
import { reactive, onBeforeMount } from 'vue';

export default {
    name: 'CustomerManagement',
    setup(){
        //Vuex store data call fucntion
        const store = useStore();

        //Toast Message call
        const toast = useToast();

        const viewPrivacyLeadsModel = reactive({
            modalState: false,
            modaldata: '',
        });

        //Customer initial data storing state
        const allLeadData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            activityStatus: 'all',
            search: null,
            dateRange: null
        });

        /**
         * On component get mounted require functions call
         */
        onBeforeMount( async() => {
            fetchAllLeads();
        });

        const fetchAllLeads = async (customerStatus, load) => {
            let date = null;
            //Toggle Customer status to fetch active/deactive role
            if(load === 'customerStatus'){
                allLeadData.activityStatus = customerStatus === 'active' ? 'de-active' : 'active' ;
            }

            //To clear search and filter then fetch all data
            if(load === 'resetFilters'){
                allLeadData.search = null;
                allLeadData.dateRange = null;
            }

            //Filter date wise code
            if (load === 'dateRange') {
                date = formatDate(allLeadData.dateRange[0]) + ' ' + formatDate(allLeadData.dateRange[1]);
            }

            //API call start
            try{
                allLeadData.loading = !allLeadData.loading;
                const response = await axios.get( allPrivacyLeads, {
                    params: {
                        status: allLeadData.activityStatus,
                        search: allLeadData.search,
                        filterDt: date
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                allLeadData.data = response.data.data.invoice;
                allLeadData.total = response.data.data.total;
                allLeadData.loading = !allLeadData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        const sendApproveRequest = async (slotData) => {
            const response = await axios.post( approveRequests, {}, {
                params: {
                    uuid : slotData
                },
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                }, 
            });
            if(response.data.status === 200){
                toast.add({severity:'info', summary:'Successful', detail:response.data.data, life: 3000});
                fetchAllLeads();
            }
        }

        const viewLeadById = async(slotData) => {
            const response = await axios.get( UpPrivacyLeadById, {
                params: {
                    uuid: slotData,
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });
            if (response.data.status === 200) {
                viewPrivacyLeadsModel.modalState = true;
                viewPrivacyLeadsModel.modaldata = response.data.data;
            }
        }

        const daysRemaining = function(date) {
            const d = new Date(date);
            const today = new Date();
            const diff = Math.max(45-Math.ceil((today - d)/(24*60*60*1000)), 0);
            return("("+ diff + " days remaining)");
        }

        return{
            allLeadData,
            usFormatDate,
            formatDate,
            sendApproveRequest,
            viewLeadById,
            viewPrivacyLeadsModel,
            daysRemaining
        }

    }

}
</script>

<style lang="scss" scoped>
.request {
    width: 6.357rem;
    padding: 0.5rem 10px;
}
@import '../../../../assets/style/component/userportal/pages/PrivacyFormLeads/PrivacyFormLeads';
// @import '../../../../assets/style/component/userportal/pages/ManageBranding/ManageBranding';
</style>